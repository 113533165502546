// logo
export const palmtechLogo = (
  <svg width="166" height="34" viewBox="0 0 166 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_848_5935)">
      <path
        d="M59.9153 6.30604C60.7726 6.27702 61.6263 6.43935 62.4234 6.78298C63.2206 7.12661 63.9442 7.64422 64.5495 8.30374C65.1546 8.97037 65.6284 9.76363 65.942 10.6353C66.2557 11.5069 66.4027 12.4387 66.3741 13.3739C66.4021 14.3091 66.2548 15.2406 65.9411 16.1122C65.6275 16.9837 65.1541 17.7771 64.5495 18.4441C63.9439 19.1028 63.2201 19.6196 62.423 19.9622C61.6258 20.3048 60.7723 20.4661 59.9153 20.4359H56.4169V25.8624H52.2456V6.30604H59.9153ZM59.2695 16.7501C59.66 16.7664 60.0491 16.6902 60.4097 16.5268C60.7704 16.3634 61.0939 16.1167 61.3578 15.8039C61.9104 15.14 62.2021 14.2679 62.1705 13.3739C62.2033 12.4797 61.9114 11.6072 61.3578 10.944C61.0962 10.6324 60.7755 10.3859 60.4178 10.2216C60.0601 10.0572 59.6739 9.97885 59.2856 9.99185H56.433V16.7501H59.2695Z"
        fill="#1F2120"
      />
      <path
        d="M73.1987 26.2188C72.3958 26.2587 71.595 26.1013 70.856 25.7585C70.117 25.4156 69.4588 24.8961 68.9306 24.2387C67.8451 22.8029 67.2855 20.9831 67.359 19.1276C67.2928 17.2742 67.8689 15.4611 68.9736 14.0458C69.5013 13.3838 70.1593 12.8591 70.899 12.5102C71.6387 12.1614 72.4412 11.9975 73.2472 12.0305C74.0607 12.0052 74.8667 12.2063 75.5884 12.6147C76.2446 12.9956 76.7821 13.579 77.1332 14.2911V12.3635H81.0461V25.8625H77.1332V23.9583C76.7617 24.6573 76.2122 25.2252 75.5508 25.5938C74.8273 26.0125 74.0186 26.2274 73.1987 26.2188ZM74.3344 22.6732C74.7451 22.6982 75.1557 22.6206 75.5345 22.4464C75.9134 22.2723 76.2503 22.0061 76.5196 21.6685C77.051 20.9502 77.3261 20.0482 77.2946 19.1276C77.3238 18.2146 77.0486 17.3207 76.5196 16.61C76.2503 16.2724 75.9134 16.0063 75.5345 15.8322C75.1557 15.658 74.7451 15.5804 74.3344 15.6054C73.9312 15.5879 73.5294 15.6667 73.1574 15.8363C72.7854 16.0059 72.4522 16.262 72.1815 16.5867C71.6244 17.2914 71.3357 18.2 71.3741 19.1276C71.3389 20.0582 71.627 20.9693 72.1815 21.6802C72.4502 22.0069 72.7819 22.2652 73.1531 22.4368C73.5243 22.6084 73.9258 22.6892 74.329 22.6732H74.3344Z"
        fill="#1F2120"
      />
      <path d="M84.3135 25.8621V6.30566H88.2694V25.8621H84.3135Z" fill="#1F2120" />
      <path
        d="M91.4985 25.8625V12.3926H95.4384V14.3494C95.7186 13.6528 96.1905 13.0673 96.7893 12.673C97.4511 12.2341 98.2153 12.011 98.9907 12.0305C100.943 12.0305 102.238 12.8599 102.877 14.5188C103.219 13.7689 103.753 13.1421 104.416 12.7139C105.141 12.252 105.97 12.0156 106.811 12.0305C109.743 12.0305 111.21 13.8549 111.214 17.5037V25.8625H107.269V18.2689C107.269 16.5165 106.655 15.617 105.417 15.617C104.028 15.617 103.334 16.7151 103.334 18.9114V25.8625H99.3674V18.2689C99.3674 16.5165 98.7592 15.6403 97.5428 15.6403C97.2454 15.6198 96.9478 15.6764 96.6745 15.8054C96.4012 15.9344 96.1602 16.1321 95.9712 16.3822C95.6106 16.8728 95.433 17.7257 95.433 18.9348V25.8625H91.4985Z"
        fill="#1F2120"
      />
      <path
        d="M120.655 22.5332C121.11 22.5382 121.564 22.5108 122.016 22.4514V25.8627C121.149 26.0226 120.27 26.0969 119.39 26.0847C118.994 26.0902 118.598 26.0628 118.206 26.0029C117.807 25.9276 117.419 25.8001 117.048 25.6232C116.638 25.4528 116.269 25.1808 115.972 24.8288C115.65 24.4019 115.41 23.9093 115.267 23.3802C115.056 22.657 114.956 21.9013 114.971 21.143V15.7749H113.028V12.3929H114.987V8.40332H118.948V12.3929H121.758V15.7749H118.948V20.2435C118.948 21.1547 119.094 21.768 119.39 22.0718C119.739 22.3992 120.194 22.5649 120.655 22.5332Z"
        fill="#1F2120"
      />
      <path
        d="M136.478 18.4262C136.475 19.0528 136.441 19.6787 136.376 20.3013H127.226C127.219 20.687 127.288 21.07 127.428 21.4246C127.568 21.7792 127.776 22.0972 128.039 22.3574C128.628 22.8679 129.369 23.1256 130.122 23.0817C131.324 23.0817 132.097 22.6631 132.441 21.8258H136.3C136.2 22.4873 135.97 23.1175 135.625 23.6739C135.281 24.2302 134.83 24.6996 134.304 25.0502C133.057 25.9124 131.599 26.3461 130.122 26.2944C128.012 26.2944 126.349 25.6616 125.132 24.396C123.916 23.1304 123.306 21.378 123.302 19.1389C123.302 16.9426 123.912 15.2058 125.132 13.9285C126.465 12.7135 128.145 12.0368 129.885 12.0133C131.626 11.9899 133.32 12.6212 134.68 13.8C135.27 14.3846 135.736 15.1009 136.046 15.8994C136.356 16.698 136.504 17.56 136.478 18.4262ZM127.215 17.6435H132.597C132.627 16.8817 132.378 16.138 131.903 15.5757C131.643 15.3217 131.338 15.1267 131.007 15.0024C130.676 14.878 130.326 14.8266 129.976 14.8514C129.613 14.8299 129.249 14.887 128.907 15.0193C128.564 15.1517 128.249 15.3567 127.979 15.6225C127.732 15.8851 127.536 16.1998 127.405 16.5473C127.274 16.8949 127.209 17.2679 127.215 17.6435Z"
        fill="#1F2120"
      />
      <path
        d="M144.821 26.2769C143.932 26.3242 143.044 26.1739 142.211 25.8354C141.378 25.4969 140.618 24.9773 139.977 24.3084C139.369 23.6218 138.896 22.8087 138.586 21.9182C138.276 21.0277 138.136 20.0782 138.174 19.1272C138.136 18.1779 138.276 17.2301 138.586 16.3414C138.896 15.4528 139.369 14.6418 139.977 13.9577C140.619 13.2918 141.38 12.7748 142.213 12.4384C143.046 12.102 143.933 11.9531 144.821 12.0009C146.426 11.9227 148.007 12.4466 149.299 13.4846C149.863 13.9708 150.327 14.5793 150.661 15.2714C150.996 15.9634 151.193 16.7236 151.242 17.5033H147.307C147.011 16.2416 146.188 15.605 144.831 15.605C144.458 15.5844 144.085 15.6557 143.74 15.8135C143.395 15.9713 143.087 16.2115 142.84 16.5162C142.319 17.2603 142.063 18.1831 142.119 19.1155C142.063 20.0461 142.319 20.9672 142.84 21.709C143.087 22.0185 143.395 22.2625 143.742 22.4225C144.09 22.5825 144.466 22.6541 144.842 22.6319C146.199 22.6319 147.024 22.0361 147.318 20.8445H151.242C151.189 21.6152 150.987 22.3653 150.651 23.0464C150.314 23.7275 149.85 24.3246 149.288 24.799C147.996 25.8268 146.421 26.3479 144.821 26.2769Z"
        fill="#1F2120"
      />
      <path
        d="M161.468 12.0301C164.489 12.0301 166 13.8642 166 17.5325V25.8621H162.039V18.9285C162.102 18.0555 161.926 17.1817 161.533 16.4168C161.336 16.1441 161.079 15.9294 160.785 15.7927C160.492 15.6559 160.171 15.6014 159.853 15.6341C158.26 15.6341 157.464 16.6952 157.464 18.8175V25.8621H153.524V6.30566H157.464V14.349C158.196 12.8069 159.531 12.0301 161.468 12.0301Z"
        fill="#1F2120"
      />
      <path d="M21.1954 4.25L0 17.5271L7.35756 22.1416L21.1954 13.4674L27.6756 17.5271L25.146 19.1159L21.1954 16.6334L13.8378 21.2479L25.146 28.3333L42.3854 17.5271L21.1954 4.25Z" fill="#16B58F" />
    </g>
    <defs>
      <clipPath id="clip0_848_5935">
        <rect width="166" height="24.0833" fill="white" transform="translate(0 4.25)" />
      </clipPath>
    </defs>
  </svg>
);

export const curve = (
  <svg width="18" height="46" viewBox="1 30 18 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.770996 0V35.2772C0.770996 40.8001 5.24815 45.2772 10.771 45.2772H17.5939" stroke="#D5D5D5" />
  </svg>
);
export const curveEnd = (
  <svg width="18" height="46" viewBox="0 30 18 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.770996 0V35.2772C0.770996 40.8001 5.24815 45.2772 10.771 45.2772H17.5939" stroke="#D5D5D5" />
  </svg>
);
export const copyIcon = (
  <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 17L13.6 15.6L15.175 14H11V12H15.175L13.6 10.4L15 9L19 13L15 17ZM0 17V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H14C14.55 0 15.0208 0.195833 15.4125 0.5875C15.8042 0.979167 16 1.45 16 2V7.075C15.8333 7.04167 15.6667 7.02083 15.5 7.0125C15.3333 7.00417 15.1667 7 15 7C14.8333 7 14.6667 7.00417 14.5 7.0125C14.3333 7.02083 14.1667 7.04167 14 7.075V2H2V12H9.075C9.04167 12.1667 9.02083 12.3333 9.0125 12.5C9.00417 12.6667 9 12.8333 9 13C9 13.1667 9.00417 13.3333 9.0125 13.5C9.02083 13.6667 9.04167 13.8333 9.075 14H3L0 17ZM4 6H12V4H4V6ZM4 10H9V8H4V10Z"
      fill="#979797"
    />
  </svg>
);
export const arrowDown = (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8335 1.6912L7.70727 6.81743C7.31675 7.20795 6.68358 7.20795 6.29306 6.81743L1.16683 1.6912" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
export const duplicateIcon = (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8332 15.8335H5.6665C5.20817 15.8335 4.81581 15.6703 4.48942 15.3439C4.16303 15.0175 3.99984 14.6252 3.99984 14.1668V2.50016C3.99984 2.04183 4.16303 1.64947 4.48942 1.32308C4.81581 0.996691 5.20817 0.833496 5.6665 0.833496H11.4998L16.4998 5.8335V14.1668C16.4998 14.6252 16.3366 15.0175 16.0103 15.3439C15.6839 15.6703 15.2915 15.8335 14.8332 15.8335ZM10.6665 6.66683V2.50016H5.6665V14.1668H14.8332V6.66683H10.6665ZM2.33317 19.1668C1.87484 19.1668 1.48248 19.0036 1.15609 18.6772C0.829698 18.3509 0.666504 17.9585 0.666504 17.5002V5.8335H2.33317V17.5002H11.4998V19.1668H2.33317Z"
      fill="#454545"
    />
  </svg>
);
export const moveOrCopyCategory = (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8332 15.8335H5.6665C5.20817 15.8335 4.81581 15.6703 4.48942 15.3439C4.16303 15.0175 3.99984 14.6252 3.99984 14.1668V2.50016C3.99984 2.04183 4.16303 1.64947 4.48942 1.32308C4.81581 0.996691 5.20817 0.833496 5.6665 0.833496H11.4998L16.4998 5.8335V14.1668C16.4998 14.6252 16.3366 15.0175 16.0103 15.3439C15.6839 15.6703 15.2915 15.8335 14.8332 15.8335ZM10.6665 6.66683V2.50016H5.6665V14.1668H14.8332V6.66683H10.6665ZM2.33317 19.1668C1.87484 19.1668 1.48248 19.0036 1.15609 18.6772C0.829698 18.3509 0.666504 17.9585 0.666504 17.5002V5.8335H2.33317V17.5002H11.4998V19.1668H2.33317Z"
      fill="#454545"
    />
  </svg>
);

export const Gallery = () => (
  <svg width="20" height="20" style={{ float: "right" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_5873_6273" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_5873_6273)">
      <path
        d="M2.49992 15.8332C2.04159 15.8332 1.64922 15.67 1.32284 15.3436C0.996446 15.0172 0.833252 14.6248 0.833252 14.1665V5.83317C0.833252 5.37484 0.996446 4.98248 1.32284 4.65609C1.64922 4.3297 2.04159 4.1665 2.49992 4.1665H10.8333C11.2916 4.1665 11.6839 4.3297 12.0103 4.65609C12.3367 4.98248 12.4999 5.37484 12.4999 5.83317V14.1665C12.4999 14.6248 12.3367 15.0172 12.0103 15.3436C11.6839 15.67 11.2916 15.8332 10.8333 15.8332H2.49992ZM14.9999 9.1665C14.7638 9.1665 14.5659 9.08664 14.4062 8.92692C14.2464 8.7672 14.1666 8.56928 14.1666 8.33317V4.99984C14.1666 4.76373 14.2464 4.56581 14.4062 4.40609C14.5659 4.24637 14.7638 4.1665 14.9999 4.1665H18.3333C18.5694 4.1665 18.7673 4.24637 18.927 4.40609C19.0867 4.56581 19.1666 4.76373 19.1666 4.99984V8.33317C19.1666 8.56928 19.0867 8.7672 18.927 8.92692C18.7673 9.08664 18.5694 9.1665 18.3333 9.1665H14.9999ZM15.8333 7.49984H17.4999V5.83317H15.8333V7.49984ZM2.49992 14.1665H10.8333V5.83317H2.49992V14.1665ZM3.33325 12.4998H9.99992L7.81242 9.58317L6.24992 11.6665L5.10408 10.1457L3.33325 12.4998ZM14.9999 15.8332C14.7638 15.8332 14.5659 15.7533 14.4062 15.5936C14.2464 15.4339 14.1666 15.2359 14.1666 14.9998V11.6665C14.1666 11.4304 14.2464 11.2325 14.4062 11.0728C14.5659 10.913 14.7638 10.8332 14.9999 10.8332H18.3333C18.5694 10.8332 18.7673 10.913 18.927 11.0728C19.0867 11.2325 19.1666 11.4304 19.1666 11.6665V14.9998C19.1666 15.2359 19.0867 15.4339 18.927 15.5936C18.7673 15.7533 18.5694 15.8332 18.3333 15.8332H14.9999ZM15.8333 14.1665H17.4999V12.4998H15.8333V14.1665Z"
        fill="#454545"
      />
    </g>
  </svg>
);

export const ReportLibraryIcon = ({ validUrl }) => {
  return (
    <svg width="20" height="20" style={{ float: "right" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5873_6273" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill={"#D9D9D9"} />
      </mask>
      <g mask="url(#mask0_5873_6273)">
        <path
          d="M2.49992 15.8332C2.04159 15.8332 1.64922 15.67 1.32284 15.3436C0.996446 15.0172 0.833252 14.6248 0.833252 14.1665V5.83317C0.833252 5.37484 0.996446 4.98248 1.32284 4.65609C1.64922 4.3297 2.04159 4.1665 2.49992 4.1665H10.8333C11.2916 4.1665 11.6839 4.3297 12.0103 4.65609C12.3367 4.98248 12.4999 5.37484 12.4999 5.83317V14.1665C12.4999 14.6248 12.3367 15.0172 12.0103 15.3436C11.6839 15.67 11.2916 15.8332 10.8333 15.8332H2.49992ZM14.9999 9.1665C14.7638 9.1665 14.5659 9.08664 14.4062 8.92692C14.2464 8.7672 14.1666 8.56928 14.1666 8.33317V4.99984C14.1666 4.76373 14.2464 4.56581 14.4062 4.40609C14.5659 4.24637 14.7638 4.1665 14.9999 4.1665H18.3333C18.5694 4.1665 18.7673 4.24637 18.927 4.40609C19.0867 4.56581 19.1666 4.76373 19.1666 4.99984V8.33317C19.1666 8.56928 19.0867 8.7672 18.927 8.92692C18.7673 9.08664 18.5694 9.1665 18.3333 9.1665H14.9999ZM15.8333 7.49984H17.4999V5.83317H15.8333V7.49984ZM2.49992 14.1665H10.8333V5.83317H2.49992V14.1665ZM3.33325 12.4998H9.99992L7.81242 9.58317L6.24992 11.6665L5.10408 10.1457L3.33325 12.4998ZM14.9999 15.8332C14.7638 15.8332 14.5659 15.7533 14.4062 15.5936C14.2464 15.4339 14.1666 15.2359 14.1666 14.9998V11.6665C14.1666 11.4304 14.2464 11.2325 14.4062 11.0728C14.5659 10.913 14.7638 10.8332 14.9999 10.8332H18.3333C18.5694 10.8332 18.7673 10.913 18.927 11.0728C19.0867 11.2325 19.1666 11.4304 19.1666 11.6665V14.9998C19.1666 15.2359 19.0867 15.4339 18.927 15.5936C18.7673 15.7533 18.5694 15.8332 18.3333 15.8332H14.9999ZM15.8333 14.1665H17.4999V12.4998H15.8333V14.1665Z"
          fill={validUrl == "/report_media_library" ? "#FFFFFF" : "#a5a5a5"}
        />
      </g>
    </svg>
  );
};
