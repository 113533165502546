import { put, call } from "redux-saga/effects";
import Caller from "../../Config/Caller";
import ACTION_TYPES from "../Constants/ActionTypes";
import { API_CONSTANTS } from "../../Config/Api-constants";

export function* setDashboadrData(action) {
  try {
    var params = "";
    let response = yield call(Caller, "GET", API_CONSTANTS.LOAD_DASHBOARD, undefined, undefined, params);
    // console.log("response in saga",response)
    yield put({
      type: ACTION_TYPES.LOAD_DASHBOARD_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.LOAD_DASHBOARD_ERROR,
      payload: err,
    });
  }
}

export function* setInspectionData(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    var postData = {};
    if (action.payload.associate_company === "Palmtech" && action.payload.user_id !== "") {
      postData = {
        guid: action.payload.guid,
        associate_company: action.payload.associate_company,
        user_id: action.payload.user_id,
      };
    } else {
      postData = {
        guid: action.payload.guid,
        associate_company: action.payload.associate_company,
        user_id: action.payload.user_id,
      };
    }

    let response = yield call(Caller, "POST", API_CONSTANTS.GET_INSPECTION_DATAS, postData);
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    console.log("error res", err);
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_ERROR,
      payload: err,
    });
  }
}

// without loader
export function* setInspectionDataWithOutLoader(action) {
  try {
    var postData = {};
    if (action.payload.associate_company === "Palmtech" && action.payload.user_id !== "") {
      postData = {
        guid: action.payload.guid,
        associate_company: action.payload.associate_company,
        user_id: action.payload.user_id,
      };
    } else {
      postData = {
        guid: action.payload.guid,
        associate_company: action.payload.associate_company,
        user_id: action.payload.user_id,
      };
    }
    let response = yield call(Caller, "POST", API_CONSTANTS.GET_INSPECTION_DATAS_WITHOUT_LOADER, postData);
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_ERROR,
      payload: err,
    });
  }
}

export function* updateInspectionData(action) {
  try {
    // var postData = {
    //   action.payload,
    // };
    let response = yield call(Caller, "POST", API_CONSTANTS.UPDATE_INSPECTION_TEMPLATE_DATA, action.payload);
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_ERROR,
      payload: err,
    });
  }
}
export function* updateInspectionMediaData(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.UPDATE_INSPECTION_TEMPLATE_DATA, action.payload);
    yield put({
      type: ACTION_TYPES.DELETE_INSPECTION_MEDIA_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_ERROR,
      payload: err,
    });
  }
}
export function* updateInspectionDataWithLoader(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.UPDATE_INSPECTION_TEMPLATE_DATA, action.payload);
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_ERROR,
      payload: err,
    });
  }
}
export function* updateInspectionDataForAddInp(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.UPDATE_INSPECTION_TEMPLATE_DATA, action.payload);
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_RESPONSE_FOR_ADD_INP,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_INSPECTION_ERROR_FOR_ADD_INP,
      payload: err,
    });
  }
}
export function* uploadMedia(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.UPLOAD_MEDIA, action.payload);
    yield put({
      type: ACTION_TYPES.UPLOAD_MEDIA_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPLOAD_MEDIA_ERROR,
      payload: err,
    });
  }
}

export function* uploadBulkMedia(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.UPLOAD_BULK_MEDIA, action.payload);
    yield put({
      type: ACTION_TYPES.BULK_UPLOAD_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.BULK_UPLOAD_ERROR,
      payload: err,
    });
  }
}

export function* uploadAttachment(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.UPLOAD_ATTACHMENTS, action.payload);
    yield put({
      type: ACTION_TYPES.UPLOAD_ATTACHMENT_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPLOAD_ATTACHMENT_ERROR,
      payload: err,
    });
  }
}

export function* duplicateLine(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.DUPLICATE_LINE, action.payload);
    yield put({
      type: ACTION_TYPES.DUPLICATE_LINES_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.DUPLICATE_LINES_ERROR,
      payload: err,
    });
  }
}

export function* duplicateCategory(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.DUPLICATE_CATEGORY, action.payload);
    yield put({
      type: ACTION_TYPES.DUPLICATE_CATEGORYS_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.DUPLICATE_CATEGORYS_ERROR,
      payload: err,
    });
  }
}
export function* assignInspectorFlagToCategory(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.ASSIGN_INSPECTOR_FLAG_TO_CATEGORY, action.payload);
    yield put({
      type: ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_CATEGORY_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_CATEGORY_ERROR,
      payload: err,
    });
  }
}
export function* assignInspectorFlagToLine(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.ASSIGN_INSPECTOR_FLAG_TO_LINE, action.payload);
    yield put({
      type: ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_LINE_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.ASSIGN_INSPECTOR_FLAG_TO_LINE_ERROR,
      payload: err,
    });
  }
}
export function* editInspectorFlagList(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.EDIT_INSPECTOR_FLAG_LIST, action.payload);
    yield put({
      type: ACTION_TYPES.EDIT_INSPECTOR_FLAG_LIST_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.EDIT_INSPECTOR_FLAG_LIST_ERROR,
      payload: err,
    });
  }
}

export function* markReportComplete(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.MARK_COMPLETE, action.payload);
    yield put({
      type: ACTION_TYPES.MARK_COMPLETE_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.MARK_COMPLETE_ERROR,
      payload: err,
    });
  }
}
export function* gerenaratePorchCall(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.PORCH_PDF_GENERATE, action.payload);
    yield put({
      type: ACTION_TYPES.PORCH_PDF_GENERATE_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.PORCH_PDF_GENERATE_ERROR,
      payload: err,
    });
  }
}

// ISN Mark as complete
export function* markReportCompleteIsn(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.MARK_COMPLETE_ISN, action.payload);
    yield put({
      type: ACTION_TYPES.MARK_COMPLETE_RESPONSE_ISN,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.MARK_COMPLETE_ERROR_ISN,
      payload: err,
    });
  }
}
export function* markReportCompleteIsnSendReport(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.MARK_COMPLETE_ISN, action.payload);
    yield put({
      type: ACTION_TYPES.MARK_COMPLETE_ISN_SEND_REPORT_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.MARK_COMPLETE_ISN_SEND_REPORT_RESPONSE,
      payload: err,
    });
  }
}

export function* getCategoryList(action) {
  let formData = {
    template_guid: action.payload,
  };
  try {
    yield put({
      type: ACTION_TYPES.GET_ADD_CAT_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.GET_CATEGORY_LIST, formData);
    yield put({
      type: ACTION_TYPES.GET_CATEGORY_LIST_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_CATEGORY_LIST_ERROR,
      payload: err,
    });
  }
}

// get template list
export function* getTemplateList(action) {
  try {
    var params = "";
    let response = yield call(Caller, "GET", `V2/get_master_templates?guid=${action.payload}`, undefined, undefined, params);
    // console.log("response in saga",response)
    yield put({
      type: ACTION_TYPES.GET_TEMPLATE_LIST_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_TEMPLATE_LIST_ERROR,
      payload: err,
    });
  }
}

export function* swapInspectionDetails(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_INSPECTION_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.SWAP_INSPECTION_DETAILS, action.payload);
    yield put({
      type: ACTION_TYPES.SWAP_INSPECTION_DETAILS_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.SWAP_INSPECTION_DETAILS_ERROR,
      payload: err,
    });
  }
}

// deleted templated data
export function* deletedTemplateData(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.GET_DELETED_TEMPLATE_DATA, action.payload);
    yield put({
      type: ACTION_TYPES.GET_DELETED_TEMPLATE_DATA_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_DELETED_TEMPLATE_DATA_ERROR,
      payload: err,
    });
  }
}

// activate categories and lines
export function* activateLine(action) {
  yield put({
    type: ACTION_TYPES.ACTIVATE_CURRENT_LINE,
    payload: action.payload,
  });
}
export function* clearActivatedLine(action) {
  yield put({
    type: ACTION_TYPES.CLEAR_ACTIVATED_CURRENT_LINE,
    payload: action.payload,
  });
}

// default lines
export function* getCategoryLineList(action) {
  let formData = {
    template_guid: action.payload.templateGuid,
    parent_uuid: action.payload.parentUuid === null ? "" : action.payload.parentUuid,
  };
  try {
    yield put({
      type: ACTION_TYPES.GET_ADD_LINE_LOADER,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.GET_CATEGORY_LINE_LIST, formData);
    yield put({
      type: ACTION_TYPES.GET_CATEGORY_LINE_LIST_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_CATEGORY_LINE_LIST_ERROR,
      payload: err,
    });
  }
}

export function* getAllCategoryListData(action) {
  try {
    var params = "";
    let response = yield call(Caller, "GET", API_CONSTANTS.GET_DEFAULT_CATEGORIES_DATA, undefined, undefined, params);
    yield put({
      type: ACTION_TYPES.GET_CATEGORY_LIST_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_CATEGORY_LIST_DATA_ERROR,
      payload: err,
    });
  }
}
export function* getMasterTemplateData(action) {
  try {
    var params = "";
    let response = yield call(Caller, "GET", API_CONSTANTS.GET_MASTER_TEMPLATES, undefined, undefined, params);
    yield put({
      type: ACTION_TYPES.GET_MASTER_TEMPLATES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_MASTER_TEMPLATES_ERROR,
      payload: err,
    });
  }
}

export function* updateISNTemplateData(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_LOADER_FOR_TEMPLATE_UPDATE,
      payload: "",
    });
    let response = yield call(Caller, "POST", API_CONSTANTS.SETUP_INSPECTION_TEMPLATE, action.payload);
    yield put({
      type: ACTION_TYPES.UPDATE_ISN_TEMPLATE_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_ISN_TEMPLATE_ERROR,
      payload: err,
    });
  }
}

export function* scroPageToTop(action) {
  yield put({
    type: ACTION_TYPES.SCROLL_PAGE_TOP,
    payload: action.payload,
  });
}
export function* staticBackGround(action) {
  yield put({
    type: ACTION_TYPES.SET_STATIC_BACKGROUND,
    payload: action.payload,
  });
}
export function* editCategoryStatus(action) {
  yield put({
    type: ACTION_TYPES.EDIT_CAT_STATUS,
    payload: action.payload,
  });
}
export function* editLineStatus(action) {
  yield put({
    type: ACTION_TYPES.EDIT_LINE_STATUS,
    payload: action.payload,
  });
}

// REORDER
export function* reorderCategoryStatus(action) {
  yield put({
    type: ACTION_TYPES.REORDER_CATEGORY_STATUS,
    payload: action.payload,
  });
}
export function* bulkUploadMediaStatus(action) {
  yield put({
    type: ACTION_TYPES.BULK_UPLOAD_MEDIA_LENGTH_STATUS,
    payload: action.payload,
  });
}
export function* bulkUploadingStatus(action) {
  yield put({
    type: ACTION_TYPES.BULK_UPLOADING_STAUS,
    payload: action.payload,
  });
}
export function* successfulbulkUploadMediaStatus(action) {
  yield put({
    type: ACTION_TYPES.SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH_STATUS,
    payload: action.payload,
  });
}
export function* failBulkUploadMediaStatus(action) {
  yield put({
    type: ACTION_TYPES.FAIL_BULK_UPLOAD_MEDIA_LENGTH_STATUS,
    payload: action.payload,
  });
}
export function* failedBulkUploadMediaStatus(action) {
  yield put({
    type: ACTION_TYPES.FAILED_BULK_UPLOAD_MEDIA_STATUS,
    payload: action.payload,
  });
}

export function* reorderLinesStatus(action) {
  yield put({
    type: ACTION_TYPES.REORDER_LINES_STATUS,
    payload: action.payload,
  });
}
// default lines
export function* getcommentLibraryList(action) {
  try {
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_LIST_LOADER,
      payload: "",
    });
    var params = "";
    let response = yield call(
      Caller,
      "GET",
      `get-comment-library-list?page=${action.payload?.page}&category_uuid=${action.payload?.category_uuid}&template_guid=${action.payload?.template_guid}&category_id=${action.payload.category_id}`,
      undefined,
      undefined,
      params
    );
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_LIST_RESPONSE,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_LIST_ERROR,
      payload: err,
    });
  }
}

export function* categoryLineRelation(action) {
  try {
    var params = "";
    let response = yield call(
      Caller,
      "GET",
      `validate-category-line-relation?templateGuid=${action.payload?.templateGuid}&categoryUuid=${action.payload?.categoryUuid}&lineUuid=${action.payload.lineUuid}&lineParentUuid=${action.payload.lineParentUuid}`,
      undefined,
      undefined,
      params
    );
    yield put({
      type: ACTION_TYPES.VALIDATE_CATGEORY_LINE_RELATION_RESPONSE,
      payload: response.data.success,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.VALIDATE_CATGEORY_LINE_RELATION_ERROR,
      payload: err,
    });
  }
}

export function* addCommentsToReportFromCl(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.ADD_COMMENT_TO_REPORT, action.payload);
    yield put({
      type: ACTION_TYPES.ADD_COMMENT_TOREPORT_FROM_CL_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.ADD_COMMENT_TOREPORT_FROM_CL_ERROR,
      payload: err,
    });
  }
}
export function* updateClCommentStatus(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.UPDATE_CL_COMMENT_DETAILS, action.payload);
    yield put({
      type: ACTION_TYPES.UPDATE_CL_COMMENT_STATUS_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_CL_COMMENT_STATUS_ERROR,
      payload: err,
    });
  }
}
export function* addNewNarrativeComment(action) {
  try {
    let response = yield call(Caller, "POST", API_CONSTANTS.ADD_CL_COMMENT, action.payload);
    yield put({
      type: ACTION_TYPES.ADD_NEW_NARRATIVE_COMMENT_RESPONSE,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.ADD_NEW_NARRATIVE_COMMENT_ERROR,
      payload: err,
    });
  }
}
export function* updateDropdownValues(action) {
  try {
    yield call(Caller, "POST", API_CONSTANTS.UPDATE_COMMENT_DROPDOWN, action.payload);
    yield put({
      type: ACTION_TYPES.UPDATE_DROPDOWNVALUES_RESPONSE,
      payload: action.payload,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_DROPDOWNVALUES_ERROR,
      payload: err,
    });
  }
}
export function* getCLTagList(action) {
  try {
    var params = "";
    let response = yield call(Caller, "GET", API_CONSTANTS.GET_CL_TAG, undefined, undefined, params);
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_TAG_LIST_RESPONSE,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_TAG_LIST_ERROR,
      payload: err,
    });
  }
}
export function* getClSummaryTagList(action) {
  try {
    var params = "";
    let response = yield call(Caller, "GET", API_CONSTANTS.GET_CL_SUMMARY_TAG, undefined, undefined, params);
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_RESPONSE,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_ERROR,
      payload: err,
    });
  }
}
export function* toggleReportSidebar(action) {
  yield put({
    type: ACTION_TYPES.UPDATE_REPORT_SIDEBAR_STATUS,
    payload: action.payload,
  });
}

export function* showCommentModal(action) {
  yield put({
    type: ACTION_TYPES.SHOW_COMMENT_LIBRARY_MODAL_RESPONSE,
    payload: action.payload,
  });
}
