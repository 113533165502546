import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import EditColorIndicator from "./EditColorIndicator";
import { ApiCallForAssignInspectorToCategory, ApiCallForAssignInspectorToLine } from "../../Helpers/ApiCall";
import { getInspectionData } from "../../Redux/Actions/CommonActions";
import { Base64 } from "js-base64";
import Preloader from "../../Helpers/Preloader";
// import Preloader from "../../Helpers/Preloader";

const AssignColorIndicatorModal = ({
  assignColorIndicatorModal,
  setAssignColorIndicatorModal,
  handleAssignColor,
  assignColorList,
  templateId,
  assignColorIndicatorCategory,
  templateData,
  setCategoryMenuAnchorEl,
  action,
  assignColorIndicatorLine,
  setLineMenuAnchorEl,
}) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  const [editColorIndicator, setEditColorIndicator] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [loader, setLoader] = useState(false);

  const closeModal = () => {
    setAssignColorIndicatorModal(false);
    if (action === "category") {
      setCategoryMenuAnchorEl(null);
    } else {
      setLineMenuAnchorEl(null);
    }
  };

  const toggleColorSelection = (colorCode) =>
    setSelectedColors((prevSelected) => (prevSelected.includes(colorCode) ? prevSelected.filter((code) => code !== colorCode) : [...prevSelected, colorCode]));
  const assignColorsToCategory = async () => {
    setLoader(true);
    if (action === "category") {
      const formData = {
        template_guid: templateData?.template_guid,
        template_id: templateId,
        category_id: assignColorIndicatorCategory?.id,
        inspection_id: storeData.inspectionData?.data?.inspection_id,
        template_type: templateData?.is_narrative_template === 0 ? "1" : templateData?.is_narrative_template === 1 ? "2" : "3",
        inspector_flagging_guids: selectedColors,
      };
      await ApiCallForAssignInspectorToCategory(dispatch, formData);
    } else {
      const formData = {
        template_guid: templateData?.template_guid,
        template_id: templateId,
        category_id: assignColorIndicatorCategory?.id,
        line_uuid: assignColorIndicatorLine?.uuid,
        inspection_id: storeData.inspectionData?.data?.inspection_id,
        template_type: templateData?.is_narrative_template === 0 ? "1" : templateData?.is_narrative_template === 1 ? "2" : "3",
        inspector_flagging_guids: selectedColors,
      };
      await ApiCallForAssignInspectorToLine(dispatch, formData);
    }
    setTimeout(() => {
      callInitiateAPI();
      setLoader(false);
    }, 2500);
    closeModal();
  };

  const callInitiateAPI = async () => {
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
    let guid = sessionStorage.getItem("guid");
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    dispatch(
      getInspectionData({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
  };

  useEffect(() => {
    if (action === "category") {
      if (assignColorList) {
        const preSelected = assignColorList.filter((color) => assignColorIndicatorCategory?.inspector_flagging_guids?.includes(color.guid))?.map((color) => color.guid);
        setSelectedColors(preSelected);
      }
    } else {
      if (assignColorList) {
        const preSelected = assignColorList.filter((color) => assignColorIndicatorLine?.inspector_flagging_guids?.includes(color.guid))?.map((color) => color.guid);
        setSelectedColors(preSelected);
      }
    }
  }, []);

  const renderColorIndicators = () =>
    assignColorList
      ?.filter((color) => color?.is_deleted !== 1)
      ?.map((color) => (
        <Typography
          key={color.guid}
          className={`assign_color_bordered__text ${selectedColors.includes(color.guid) ? "assign_color_selected" : ""}`}
          onClick={() => toggleColorSelection(color.guid)}
          style={{
            cursor: "pointer",
            "&:hover": { backgroundColor: "transparent !important" },
          }}
        >
          <span className="bordered__text__content" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <div
              style={{
                width: "18px",
                height: "18px",
                borderRadius: "50%",
                backgroundColor: color.color_code,
                border: "2px solid #FFFFFF",
                paddingLeft: "12px",
              }}
            ></div>
            {color.name}
          </span>
          {selectedColors?.includes(color?.guid) && <DoneIcon className="assign_color_selected_icon" />}
        </Typography>
      ));

  return (
    <>
      <Preloader showPreloader={loader} />
      <Dialog className="dialog-container-new-category" open={assignColorIndicatorModal} onClose={closeModal} aria-labelledby="category-modal" aria-describedby="category-modal-description">
        <div className="close-wrapper-new-line">
          <CloseIcon onClick={closeModal} id="close_description_modal" />
        </div>
        <DialogTitle className="new-category-dialog-title" sx={{ marginBottom: "2px !important" }}>
          Color Indicator
        </DialogTitle>
        <span className="category-dialog-subtitle">Set who is responsible for what.</span>
        <DialogContent className="dialog-content-new-category">
          <Grid container className="input-controll">
            <Grid item xs={12}>
              {renderColorIndicators()}
              <Typography className="assign_color_bordered__text" id="add_new_des_button" onClick={() => setEditColorIndicator(true)}>
                <span className="edit-icon-text-container">
                  <MdEdit className="edit-icon" />
                  <span className="bordered__text__content">Edit list</span>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className="new-category-modal-action">
          <Button className="add-category-btn" id="save_description" onClick={assignColorsToCategory}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {editColorIndicator && (
        <EditColorIndicator
          assignColorIndicatorModal={assignColorIndicatorModal}
          setAssignColorIndicatorModal={setAssignColorIndicatorModal}
          editColorIndicator={editColorIndicator}
          setEditColorIndicator={setEditColorIndicator}
          handleAssignColor={handleAssignColor}
          assignColorList={assignColorList}
          storeData={storeData}
          callInitiateAPI={callInitiateAPI}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default AssignColorIndicatorModal;
