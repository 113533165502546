import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Icon, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Base64 } from "js-base64";
import { useEffect } from "react";
import { updateISNTemplates } from "../../Helpers/ApiCall";
import { useDispatch } from "react-redux";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";
import { useNavigate } from "react-router-dom";
function AddTemplateModal({ setAddTemplateModal, addTemplateModal, setDeteleteTemplateModal, deleteTemplateModal, templateList, storeData }) {
  const [selectedTemplatesGuid, setSelectedTemplatesGuid] = useState([]);
  const [cureentClickTemplates, setCurrentClickTemplate] = useState([]);
  const [showTextBoxModal, setShowTextBoxModal] = useState(false);
  const [deleteTextBoxModal, setDeleteTextBoxModal] = useState(false);
  const [deleteTemplatesList, setdeleteTemplatesList] = useState([]);
  const [deleteselectedTemplatesGuid, setDeleteSelectedTemplatesGuid] = useState([]);
  const [validate, setValidate] = useState("");
  const [addTemplateFlag, setAddTemplateFlag] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [removeInputChanged, setremoveInputChanged] = useState(false);
  const defaulttemplatearr = storeData?.inspectionData?.data?.inspectiontemplates?.map((temp) => {
    return temp?.template_guid;
  });
  const defaultDeletearr = storeData?.inspectionData?.data?.inspectiontemplates;
  const softdeletedGuidArr = deleteTemplatesList?.map((value) => {
    return value?.template_uuid;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // priviously  text model
  const handleTextModelClose = () => {
    setShowTextBoxModal(false);
  };

  // priviously deleted text model
  const handleDeleteTextModelClose = () => {
    setDeleteTextBoxModal(false);
  };

  // template add functions
  const handleAddClose = () => {
    setInputChanged(false);
    setAddTemplateModal(false);
    setSelectedTemplatesGuid([]);
    setValidate("");
  };

  // for add api
  const handleAddTemplate = async (selectedTemplatesGuid) => {
    let errorEl = document.getElementById("template_selection_error_text");
    let totalguid = selectedTemplatesGuid?.concat(defaulttemplatearr);
    if (selectedTemplatesGuid.length === 0) {
    } else {
      errorEl.innerText = "";
      const formData = {
        guid: sessionStorage.getItem("guid"),
        order_id: Base64.decode(sessionStorage.getItem("AC")) == "ISN" ? sessionStorage.getItem("guid") : sessionStorage.getItem("orderId"),
        inspection_from: Base64.decode(sessionStorage.getItem("AC")),
        template_guid: totalguid,
        is_deleted: 0,
      };
      updateISNTemplates(dispatch, formData);
      setAddTemplateFlag(true);
      setAddTemplateModal(false);
      setSelectedTemplatesGuid([]);
      setValidate("");
      setShowTextBoxModal(false);
    }
  };

  const handleTemplateSelection = (template) => {
    const currentIndex = selectedTemplatesGuid.indexOf(template.guid);
    const newChecked = [...selectedTemplatesGuid];

    if (currentIndex === -1) {
      newChecked.push(template.guid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedTemplatesGuid(newChecked);
  };

  // for delete function
  const handleDeleteClose = () => {
    setremoveInputChanged(false);
    setDeteleteTemplateModal(false);
    setDeleteSelectedTemplatesGuid([]);
  };

  // delete api call
  const handleDeleteTemplate = async (deleteselectedTemplatesGuid) => {
    let errorEl = document.getElementById("template_selection_error_text2");
    if (defaultDeletearr?.length == 1) {
      errorEl.innerText = "You must have at least one template within your inspection.";
      setDeleteTextBoxModal(false);
    } else if (defaultDeletearr?.length == deleteselectedTemplatesGuid?.length) {
      errorEl.innerText = "You must have at least one template within your inspection.";
      setDeleteTextBoxModal(false);
    } else {
      errorEl.innerText = "";
      const formData = {
        guid: sessionStorage.getItem("guid"),
        order_id: Base64.decode(sessionStorage.getItem("AC")) == "ISN" ? sessionStorage.getItem("guid") : sessionStorage.getItem("orderId"),
        inspection_from: Base64.decode(sessionStorage.getItem("AC")),
        template_guid: deleteselectedTemplatesGuid,
        is_deleted: 1,
      };

      updateISNTemplates(dispatch, formData);
      const filterTemplate = defaultDeletearr.filter((val) => {
        if (!deleteselectedTemplatesGuid.includes(val?.template_guid)) {
          return true;
        }
      });
      navigate("/inspection", {
        state: {
          template: filterTemplate[0],
          template_id: filterTemplate[0].template_id,
          tempIndex: "0",
        },
      });
      setDeteleteTemplateModal(false);
      setDeleteSelectedTemplatesGuid([]);
      setDeleteTextBoxModal(false);
    }
  };

  const validation = (template) => {
    setInputChanged(true);
    setValidate("");
    handleTemplateSelection(template);
    setShowTextBoxModal(false);
  };

  const handleDeleteTemplateSelection = (template) => {
    setremoveInputChanged(true);
    const currentIndex = deleteselectedTemplatesGuid.indexOf(template.template_guid);
    const newChecked = [...deleteselectedTemplatesGuid];
    if (currentIndex === -1) {
      newChecked.push(template?.template_guid);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setDeleteSelectedTemplatesGuid(newChecked);
  };

  useEffect(() => {
    setdeleteTemplatesList(storeData?.deletedTemplateData?.data);
  }, [storeData?.deletedTemplateData]);

  useEffect(() => {
    if (storeData?.inspectionData) {
      if (addTemplateFlag) {
        navigate("/inspection", {
          state: {
            template: storeData?.inspectionData?.data?.inspectiontemplates[0],
            template_id: storeData?.inspectionData?.data?.inspectiontemplates[0].template_id,
            tempIndex: "0",
          },
        });
        setAddTemplateFlag(false);
      }
    }
  }, [storeData?.inspectionData]);

  return (
    <>
      {/* add template modal */}
      {addTemplateModal && (
        <Dialog
          className="dialog-container-new-category"
          open={addTemplateModal}
          onClose={handleAddClose}
          aria-labelledby="category-modal"
          onBackdropClick="false"
          aria-describedby="category-modal-description"
        >
          <div className="close-wrapper-new-line">
            <div className="close-wrapper-attachment">
              <CloseIcon onClick={handleAddClose} />
            </div>
          </div>
          <DialogTitle className="new-category-dialog-title mb-6">{"Add templates"}</DialogTitle>
          <span className="category-dialog-subtitle p-custom">Choose templates below to add. </span>
          <DialogContent className="dialog-content-new-category">
            <Grid container className="input-controll">
              <Grid item xs={12}>
                {templateList?.map((template, index) =>
                  defaulttemplatearr?.includes(template?.guid) ? (
                    <Grid container display={"flex"} gap={2} key={index} className={`bordered__text ${defaulttemplatearr.includes(template?.guid) && "cat_selected2"}`}>
                      <Grid item xs={0.5} display={"flex"} alignItems={"center"}>
                        {template?.is_pdf_template === 1 ? <PictureAsPdfOutlinedIcon /> : null}
                      </Grid>
                      <Grid item xs={9}>
                        <span className="bordered__text__content">{template?.name}</span>
                      </Grid>
                      <Grid item xs={1}>
                        <DoneIcon className="cat_seleted_icon" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      display={"flex"}
                      gap={2}
                      key={index}
                      onClick={() =>
                        softdeletedGuidArr?.includes(template?.guid)
                          ? selectedTemplatesGuid?.includes(template?.guid)
                            ? validation(template)
                            : (setShowTextBoxModal(true), setCurrentClickTemplate(template))
                          : validation(template)
                      }
                      className={`bordered__text ${selectedTemplatesGuid?.includes(template?.guid) && "cat_selected2"}`}
                    >
                      <Grid item xs={0.5} display={"flex"} alignItems={"center"}>
                        {template?.is_pdf_template === 1 ? <PictureAsPdfOutlinedIcon /> : null}
                      </Grid>
                      <Grid item xs={9}>
                        <span className="bordered__text__content">
                          {template?.name}
                          {softdeletedGuidArr?.includes(template?.guid) ? " (previously deleted)" : ""}
                        </span>
                      </Grid>
                      <Grid item xs={1}>
                        <DoneIcon className="cat_seleted_icon" />
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="template-selection-modal-action">
            <div id="template_selection_error_text">{validate}</div>
            <Button
              disabled={!inputChanged || selectedTemplatesGuid?.length == 0}
              className="add-category-btn"
              onClick={() => {
                handleAddTemplate(selectedTemplatesGuid);
              }}
            >
              continue
            </Button>
            <Button className="go-back-btn" onClick={handleAddClose}>
              go back
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* delete template modal */}
      {deleteTemplateModal && (
        <Dialog
          className="dialog-container-new-category"
          open={deleteTemplateModal}
          onClose={handleDeleteClose}
          aria-labelledby="category-modal"
          onBackdropClick="false"
          aria-describedby="category-modal-description"
        >
          <div className="close-wrapper-new-line">
            <div className="close-wrapper-attachment">
              <CloseIcon onClick={handleDeleteClose} />
            </div>
          </div>
          <DialogTitle className="new-category-dialog-title mb-6">{"Remove templates"}</DialogTitle>
          <span className="category-dialog-subtitle p-custom">You must have at least one template in your inspection. </span>
          <DialogContent className="dialog-content-new-category">
            <Grid container className="input-controll">
              <Grid item xs={12}>
                {defaultDeletearr?.map((template, index) => (
                  // defaulttemplatearr.includes(template.guid)?
                  <Typography
                    key={index}
                    onClick={() => handleDeleteTemplateSelection(template)}
                    className={`bordered__text ${deleteselectedTemplatesGuid.includes(template.template_guid) && "cat_selected2"}`}
                  >
                    <span className="bordered__text__content">
                      <span className="delete_modal_pdf_icon"> {template?.is_pdf_template === 1 ? <PictureAsPdfOutlinedIcon /> : null}</span>
                      <span
                        className="bordered__text__content"
                        style={{
                          marginLeft: template?.is_pdf_template !== 1 ? "22px" : undefined,
                        }}
                      >
                        {" "}
                        {template?.name}{" "}
                      </span>
                    </span>
                    <DoneIcon className="cat_seleted_icon" />
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="template-selection-modal-action">
            <div id="template_selection_error_text2"></div>
            <Button
              className="add-category-btn"
              disabled={!removeInputChanged || deleteselectedTemplatesGuid?.length == 0}
              onClick={() => (deleteselectedTemplatesGuid?.length == 0 ? setDeteleteTemplateModal(false) : setDeleteTextBoxModal(true))}
            >
              remove selected
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* text Box modal  */}
      {showTextBoxModal && (
        <Dialog className="dialog-container-add-template" open={showTextBoxModal} onClose={handleTextModelClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
          <div className="close-wrapper-new-line">
            <div className="close-wrapper-attachment">
              <CloseIcon onClick={handleTextModelClose} />
            </div>
          </div>
          <DialogContent className="dialog-container-add-template" sx={{ paddingBottom: "0px !important" }}>
            <DialogTitle
              sx={{
                fontSize: "16px !important",
                paddingLeft: "0px !important",
                fontWeight: 700,
              }}
            >
              {"Add template"}
            </DialogTitle>

            <Grid container className="input-controll" sx={{ paddingBottom: "0px !important" }}>
              <Grid item xs={12}>
                <span>This action will add back your previously deleted template.</span>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="new-category-modal-action">
            <Button
              sx={{
                fontWeight: "700 !important",
                ":hover": { backgroundColor: "transparent" },
              }}
              onClick={() => setShowTextBoxModal(false)}
            >
              GO BACK
            </Button>
            <Button
              sx={{
                fontWeight: "700 !important",
                paddingLeft: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
              onClick={() => {
                validation(cureentClickTemplates);
              }}
            >
              ADD TEMPLATE
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* text box modal for remove */}
      <DeleteConfirmationDialog
        open={deleteTextBoxModal}
        handleClose={handleDeleteTextModelClose}
        title={"Remove templates"}
        contentText={"Are you sure you want to delete this template and all associated data?"}
        cancelBtnTitle={"CANCEL"}
        cancelBtnAction={handleDeleteTextModelClose}
        submitBtnTitle={"YES, DELETE"}
        submitBtnAction={handleDeleteTemplate}
        submitBtnParam={deleteselectedTemplatesGuid}
      />
    </>
  );
}

export default AddTemplateModal;
