//file imports
import { SortTextField, FilterSortTextField } from "../../Components/DropdownTextfield/DropdownTextfield";
import { getInspectionDataWithOutLoader } from "../../Redux/Actions/CommonActions";
import { reportLibrarySideBarToggle } from "../../Redux/Actions/CommonActions";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import "../../../Assets/Styles/Containers/_reportLibrarySideBar.scss";
import { useDispatch, useSelector } from 'react-redux';
import { Gallery } from "../../../Assets/Svg/Svg";
import CloseIcon from '@mui/icons-material/Close';
import RLButtonGroup from "./RLButtonGroup";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import RMLGallery from "./RMLGallery";
import { v4 as uuidv4 } from 'uuid';
import { Base64 } from "js-base64";
/**
 * Component for the Report Library Sidebar
 */
const ReportLibrarySideBar = ({ selectedMediaFiles, selectedImages, setSelectedImages, setSelectedMediaFiles, onDragToggle, setOnDragToggle }) => {
    // redux
    const dispatch = useDispatch();
    //media query
    const isTab = useMediaQuery('(min-width: 900px) and (max-width: 1100px)');
    const isMobile = useMediaQuery('(max-width: 900px)');
    //filter 
    const [sortType, setSortType] = useState("Newest")
    const [selectedId, setSelectedId] = useState('All');
    const [selectedType, setSelectedType] = useState('All');
    // Boolean to track whether the sidebar is open or closed
    const [isOpen, setIsOpen] = useState(isMobile ? false : true);
    // Boolean to track whether the sidebar is hidden or not
    const [hidden, setHidden] = useState(!isOpen);
    // Store data from Redux store
    const storeData = useSelector((state) => state.CommonReducer);
    // Array of report library data
    const [reportLibraryData, setReportLibraryData] = useState([]);
    //filter data
    const [RLInitialData, setRLInititalData] = useState([]);
    const updateTimesUsedForDeleted = (data) => {
        // Create a map to store the count of total and deleted items for each UUID
        const uuidCounts = new Map();
        // First pass: count total and deleted items for each UUID
        data.forEach(item => {
            if (!uuidCounts.has(item.uuid)) {
                uuidCounts.set(item.uuid, { total: 0, deleted: 0 });
            }
            const counts = uuidCounts.get(item.uuid);
            counts.total += 1;
            if (item.is_deleted === 1) {
                counts.deleted += 1;
            }
        });

        // Second pass: update times_used based on the counts
        data.forEach(item => {
            const counts = uuidCounts.get(item.uuid);
            if (counts.total === counts.deleted) {
                // Set times_used to 0 if all items with the same UUID are deleted
                item.times_used = 0;
            } else {
                if (item.is_report_media_library_file) item.times_used = counts.total - counts.deleted - 1;
                // Otherwise, update times_used to reflect the number of non-deleted items
                else item.times_used = counts.total - counts.deleted;
            }
        });

        return filterArrayByUUID(data);
    };

    function removeDuplicateEntries(images) {
        const seenUrls = new Set();
        return images.filter(image => {
            const url = image.onlineUrl || image.online_url; // Check for onlineUrl or online_url
            if (!url) return false; // Skip if neither URL is present

            if (seenUrls.has(url)) {
                return false; // Skip this item, it's a duplicate
            }
            seenUrls.add(url); // Mark this URL as seen
            return true; // Keep this item
        });
    }

    const filterArrayByUUID = (array) => {
        const uuidMap = new Map();

        // Reverse the array to process latest occurrences first
        array?.reverse()?.forEach(item => {
            // Prefer id, but fallback to uuid if id is not present
            const key = item.id || item.uuid;

            // If the key exists, compare and update the 'updated_at' field to the latest
            if (uuidMap.has(key)) {
                const existingItem = uuidMap.get(key);
                if (new Date(item.updated_at) > new Date(existingItem.updated_at)) {
                    existingItem.updated_at = item.updated_at; // Update the 'updated_at' field
                }
            } else {
                // Add the item to the map if it doesn't exist
                uuidMap.set(key, item);
            }
        });

        // Convert Map to an array and filter based on online status
        return Array.from(uuidMap.values()).filter(obj => {
            return !obj?.hasOwnProperty('online') || obj?.online === 'online';
        });
    };



    useEffect(() => {
        dispatch(reportLibrarySideBarToggle(isOpen))
    }, [])

    const FilterCommentNLineMedia = () => {
        const lineCommentMedia = [];
        const lineMediaUUID = [];
        const templateEditorURLString = "template-editor/";

        // Existing processing logic
        storeData?.inspectionData?.data?.inspectiontemplates
            ?.filter((e) => e?.is_pdf_template !== 1 || e?.is_narrative_template)
            ?.forEach((template) => {
                template?.categories?.forEach((category) => {
                    category?.lines?.forEach((line) => {
                        line?.line_input_controls?.filter((data) => data?.type === 'Nameplate')?.[0]?.data?.image &&
                            lineCommentMedia?.push(line?.line_input_controls?.filter((data) => data?.type === "Nameplate")?.[0]?.data?.image);
                            
                            if (line?.line_input_controls?.length > 0) {
                            line?.line_input_controls?.filter((data) => data?.type === 'Media')?.forEach((e) =>
                                e?.data?.filter((e) => !e?.onlineUrl?.startsWith(templateEditorURLString))
                                    ?.forEach((media) => {
                                        if (media?.is_deleted_from_rml !== 3) {
                                            if (media?.edited) {
                                                if (media?.is_deleted_from_rml === 0 || media?.is_deleted_from_rml === 2) {
                                                    let originalImage = {
                                                        ...media,
                                                        thumbnail: media.OriginThumbnail,
                                                        newUrl: media?.OriginUrl,
                                                        onlineUrl: media?.OriginUrl,
                                                        name: media?.orginName,
                                                        isUserMade: true,
                                                        edited: false,
                                                        editCount: 0,
                                                        id: uuidv4(),
                                                    };
                                                    lineCommentMedia.push(originalImage);
                                                }
                                                if (media?.is_deleted_from_rml === 0 || media?.is_deleted_from_rml === 1) {
                                                    let decorativeImage = {
                                                        ...media,
                                                        editCount: 0,
                                                        edited: false,
                                                        uuid: uuidv4(),
                                                    };
                                                    lineCommentMedia.push(decorativeImage);
                                                }
                                            }
                                        }
                                        if (!media?.is_deleted_from_rml || media?.is_deleted_from_rml === 0) {
                                            lineCommentMedia.push(media);
                                        }
                                    })
                            );
                        }

                        if (line?.comments?.length > 0) {
                            line?.comments?.forEach((comment) => {
                                comment?.comment_input_controls?.filter((data) => data?.type === 'Media')?.forEach((e) =>
                                    e?.data?.filter((e) => !e?.OriginUrl?.startsWith(templateEditorURLString))
                                        ?.forEach((media) => {
                                            if (media?.is_deleted_from_rml !== 3) {
                                                if (media?.edited) {
                                                    if (media?.is_deleted_from_rml === 0 || media?.is_deleted_from_rml === 2) {
                                                        let originalImage = {
                                                            ...media,
                                                            thumbnail: media.OriginThumbnail,
                                                            newUrl: media?.OriginUrl,
                                                            onlineUrl: media?.OriginUrl,
                                                            name: media?.orginName,
                                                            isUserMade: true,
                                                            edited: false,
                                                            editCount: 0,
                                                            id: uuidv4(),
                                                        };
                                                        lineCommentMedia.push(originalImage);
                                                    }
                                                    if (media?.is_deleted_from_rml === 0 || media?.is_deleted_from_rml === 1) {
                                                        let decorativeImage = {
                                                            ...media,
                                                            editCount: 0,
                                                            edited: false,
                                                            uuid: uuidv4(),
                                                        };
                                                        lineCommentMedia.push(decorativeImage);
                                                    }
                                                }
                                            }
                                            if (!media?.is_deleted_from_rml || media?.is_deleted_from_rml === 0) {
                                                lineCommentMedia.push(media);
                                            }
                                        })
                                );
                            });
                        }
                    });
                });
            });

        // Additional processing for PDF templates
        storeData?.inspectionData?.data?.inspectiontemplates
            ?.filter((e) => e?.is_pdf_template === 1)
            ?.forEach((template) => {
                template?.pdf_data
                    ?.filter((category) => category?.type === "MediaUploader")
                    ?.forEach((e) => {
                        if (e?.value?.length > 0) {
                            e?.value?.forEach((image) => {
                                if (image?.is_deleted_from_rml !== 3) {
                                    if (image?.edited) {
                                        if (image?.is_deleted_from_rml === 0 || image?.is_deleted_from_rml === 2) {
                                            let originalImage = {
                                                ...image,
                                                thumbnail: image.OriginThumbnail,
                                                newUrl: image?.OriginUrl,
                                                onlineUrl: image?.OriginUrl,
                                                name: image?.orginName,
                                                isUserMade: true,
                                                edited: false,
                                                editCount: 0,
                                                id: uuidv4(),
                                            };
                                            lineCommentMedia.push(originalImage);
                                        }
                                        if (image?.is_deleted_from_rml === 0 || image?.is_deleted_from_rml === 1) {
                                            let decorativeImage = {
                                                ...image,
                                                editCount: 0,
                                                edited: false,
                                                uuid: uuidv4(),
                                            };
                                            lineCommentMedia.push(decorativeImage);
                                        }
                                    }
                                }
                                if (!image?.is_deleted_from_rml) {
                                    lineCommentMedia.push(image);
                                }
                                else if (image?.is_deleted_from_rml === 0) lineCommentMedia.push(image);
                            });
                        }
                    });
            });

        // Assign times_used based on frequency
        const mediaFrequency = {};
        lineCommentMedia.forEach((media) => {
            const key = media?.uuid || media?.id;
            if (key) {
                mediaFrequency[key] = (mediaFrequency[key] || 0) + 1;
            }
        });

        // Update times_used in media objects
        lineCommentMedia.forEach((media) => {
            const key = media?.uuid || media?.id;
            if (key) {
                media.times_used = mediaFrequency[key];
            }
        });

        // Append missing report media
        lineCommentMedia?.forEach((e) => lineMediaUUID?.push(e?.uuid));
        storeData?.inspectionData?.data?.report_media_library_files
            ?.filter((e) => !lineMediaUUID?.includes(e?.uuid))
            ?.filter((e) => e?.is_deleted_from_rml !== 1)
            ?.forEach((e) => lineCommentMedia.push(e));

        return updateTimesUsedForDeleted(lineCommentMedia);
    };


    //filter button change
    const handleFilterButtonChange = (id, type, sortType, initialData) => {
        // Sets the selected id
        setSelectedId(id);
        // If the selected id is 'All'
        let result;
        if (id === 'All') {
            // Sets the report library data to the original data
            result = initialData;
        }
        // If the selected id is 'Images'
        else if (id === 'Images') {
            // Filters the original data to get the images only and sets the report library data to the result
            result = initialData?.filter(item => item?.media_type?.startsWith("image/") || item?.type?.startsWith("image/"));
        }
        // If the selected id is 'Videos'
        else if (id === 'Videos') {
            // Filters the original data to get the videos only and sets the report library data to the result
            result = initialData?.filter(item => item?.media_type?.startsWith("video/") || item?.type?.startsWith("video/"));
        }
        handleUsedAndUnusedMedia(id, type, sortType, result)
    }
    //filter used and unused
    const handleUsedAndUnusedMedia = (id, type, sortType, result) => {
        let ResultData = result;
        if (type === 'All') {
            ResultData = result;
        }
        else if (type === 'Used') {
            let UsedReportMediaFiles = ResultData?.filter((e) => e.times_used > 0 || e?.times_used === undefined)
            ResultData = UsedReportMediaFiles
        }
        else if (type === 'Unused') {
            let UnusedReportMediaFiles = ResultData?.filter((e) => e?.times_used === 0);
            ResultData = UnusedReportMediaFiles
        }

        SortReportMediaData(id, type, sortType, ResultData);
    }
    const SortReportMediaData = (id, type, sortType, result) => {
        // Ensure every item has a times_used key
        let ResultData = result?.map(item => {
            if (!Object.prototype.hasOwnProperty.call(item, 'times_used') || item.times_used == null) {
                item.times_used = 0; // Introduce the key with a default value of 0
            }
            return item;
        });
        if (sortType === 'Most used') {
            ResultData = result?.sort((a, b) => (b.times_used ?? 0) - (a.times_used ?? 0)); // Sort by times_used
        }
        else if (sortType === "Newest") {
            ResultData = result?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
        else if (sortType === 'Oldest') {
            ResultData = result?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }
        else if (sortType === 'File name') {
            ResultData = result?.sort((a, b) => a.name.localeCompare(b.name));
        }
        return setReportLibraryData(ResultData)
    }

    const callInitiateAPI = async () => {
        let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
        let guid = sessionStorage.getItem('guid')
        let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
        dispatch(getInspectionDataWithOutLoader({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId }))
    }

    useEffect(() => {
        callInitiateAPI()
    }, [])

    useEffect(() => {
        if (storeData) {
            let result = FilterCommentNLineMedia();
            setRLInititalData(result);
            handleFilterButtonChange(selectedId, selectedType, sortType, result)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeData]);


    useEffect(() => {
        handleFilterButtonChange(selectedId, selectedType, sortType, RLInitialData)
    }, [selectedId, selectedType, sortType, RLInitialData])

    return (
        <div>
            {/* Conditionally render the open sidebar button */}
            {!isOpen && <div onClick={() => { setIsOpen(true); dispatch(reportLibrarySideBarToggle(true)) }} className="gallery-icon-hover"><Gallery /></div>}
            {/*
                Motion div to animate the width of the sidebar
                Pass in the isOpen state to the animation
                Set the initial state to be open
                Set the hidden state to be true when the animation starts
                Set the hidden state to be the opposite of isOpen when the animation is complete
                Set the transition to be ease
            */}
            <motion.div
                hidden={hidden}
                initial={false}
                onAnimationStart={() => setHidden(false)}
                onAnimationComplete={() => setHidden(!isOpen)}
                animate={{ width: isOpen ? isMobile ? '100%' : isTab ? 290 : 375 : 0 }}
                style={{ width: isOpen ? isMobile ? '100%' : isTab ? 290 : 375 : 0, transition: 'ease' }}
            >
                {/* Conditionally render the sidebar content */}
                {isOpen && <Grid container>
                    <Grid item xs={12}>
                        <Grid container sx={{ p: { xs: "10px", md: "7px", lg: "10px" }, mt: isMobile ? 0 : -1 }}>
                            <Grid item xs={6}>
                                <Typography className="rl-header-title">REPORT MEDIA LIBRARY</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CloseIcon onClick={() => { setIsOpen(false); dispatch(reportLibrarySideBarToggle(false)) }} className="rl-header-close-icon" />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Grid container spacing={{ xs: 1, md: 0.5, lg: 0.5 }}>
                                    <Grid item xs={6} sx={{ ml: -1.3 }}>
                                        <RLButtonGroup
                                            // Pass in the report library data, setReportLibraryData, and allReportLibraryData
                                            sortType={sortType}
                                            selectedId={selectedId}
                                            selectedType={selectedType}
                                            setSelectedId={setSelectedId}
                                            RLInitialData={RLInitialData}
                                            handleFilterButtonChange={handleFilterButtonChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3.2} sx={{ ml: -1 }}>
                                        <SortTextField
                                            sortType={sortType}
                                            selectedId={selectedId}
                                            selectedType={selectedType}
                                            setSelectedType={setSelectedType}
                                            RLInitialData={RLInitialData}
                                            handleFilterButtonChange={handleFilterButtonChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2.8}>
                                        {/* will be available in next release */}
                                        <FilterSortTextField
                                            sortType={sortType}
                                            selectedId={selectedId}
                                            selectedType={selectedType}
                                            setSortType={setSortType}
                                            RLInitialData={RLInitialData}
                                            handleFilterButtonChange={handleFilterButtonChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className="rl-divider" />
                    </Grid>
                    <Grid item xs={12} sx={{ p: { xs: "10px", md: "7px", lg: "5px" } }}>
                        <Typography className="rl-content-desc">Drag and drop images to lines or comments.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RMLGallery
                            // Pass in the bucket URL and the report library data
                            selectedImages={selectedImages}
                            setOnDragToggle={setOnDragToggle}
                            setSelectedImages={setSelectedImages}
                            reportLibraryData={reportLibraryData}
                            selectedMediaFiles={selectedMediaFiles}
                            setSelectedMediaFiles={setSelectedMediaFiles}
                            bucketUrlWRW={storeData?.inspectionData?.data?.bucket_url}
                            bucketUrlPortal={storeData?.inspectionData?.data?.bucket_url_2}
                        />
                    </Grid>
                </Grid>}
            </motion.div>
        </div >
    );
}

export default ReportLibrarySideBar;